import { Frame, Toast } from '@shopify/polaris';
import React, { useState, useCallback } from 'react';

const UseToast = () => {
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const showToast = useCallback((message, isError = false) => {
        setMessage(message);
        setIsError(isError);
        setActive(true);
    }, []);

    const dismissToast = useCallback(() => {
        setActive(false);
    }, []);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const toastMarkup = active ? (
        <Frame>

            <Toast content={message} error={isError} onDismiss={dismissToast} />
        </Frame>
    ) : null;

    return {
        showToast,
        toastMarkup,
        toggleActive
    };
};

export default UseToast;
