import { AlphaCard, Button, HorizontalGrid, Page, Select, Text, TextField } from '@shopify/polaris'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";
import { AuthContext } from '../../../../ContextApi/AuthContext'
import config from '../../../../config.json'
import axios from 'axios'
import UseToast from '../../../../Hooks/UseToast';

const OneTimePlan = () => {
    //#region -- React Initialization --
    const navigate = useNavigate()
    const { sellerDetails } = useContext(AuthContext)
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { MyShopifyDomain } = sellerDetails;
    const { showToast, toastMarkup } = UseToast();

    //#endregion

    //#region -- React States --
    const [planPrice, setPlanPrice] = useState()
    const [selected, setSelected] = useState();
    const [plaNameValue, setPlaNameValue] = useState();
    const [oneTimePayMessage, setOneTimePayMessage] = useState(['Additional Customization']);
    const [planNameMessage, setPlanNameMessage] = useState("ONE TIME PAY");
    const [existingPrice, setExistingPrice] = useState(0)
    //#endregion

    //#region -- Toggler Function --
    const handleSelectChange = useCallback(
        (value) => setSelected(value),
        [],
    );
    //#endregion

    //#region -- Toggler Function --
    const handleSelectChangePlanName = useCallback(
        (value) => setPlaNameValue(value),
        [],
    );
    //#endregion


    const [fields, setFields] = useState(['']);

    const handleFieldChange = (index, value) => {
        const newFields = fields.map((field, i) => (i === index ? value : field));
        setFields(newFields);
    };

    const addField = () => {
        setFields([...fields, '']);
    };

    const removeField = (index) => {
        setFields(fields.filter((_, i) => i !== index));
    };

    //#region -- functions --
    const handleCreate = async () => {
        const requestBody = {
            shop: `${MyShopifyDomain}`,
            planAmount: planPrice,
            planNumber: selected,
            planName:plaNameValue,
            PlanMessage: fields
        }
        const url = `${config.APIURL}/customPlan/create`;
        if (!selected) {
            showToast("Select Plan Value", true)
            return
        }
        if (!planPrice) {
            showToast("Fill plan price", true)
            return
        }
        if(!plaNameValue){
            showToast("Fill plan Name", true)
            return
        }
        if(!fields){
            showToast("Fill Message", true)
            return
        }

        try {
            const response = await axios.post(url, requestBody);
            if (response.data.status === "success") {
                showToast(response.data.message)
                setTimeout(() => {
                    back_redirect()
                }, 2000);
            }
            else showToast("Failed to create. Try Again", true)
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const getPlanInfo = async () => {
        // const url = `${config.APIURL}/customPlan/getPlan?shop=${MyShopifyDomain}`
        const url = `${config.APIURL}/customPlan/getPlan?shop=${MyShopifyDomain}`
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                setExistingPrice(response?.data?.data?.planAmount);
                const messageData = response?.data?.data?.oneTimePLanMessage;
                const planMessageData = response?.data?.data?.planNameMessage;
                if(planMessageData){
                    setPlanNameMessage(planMessageData)
                }
                if (messageData && messageData.length > 0) {
                    setOneTimePayMessage(messageData)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    //#endregion

    //#region -- Values -- 
    const options = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
    ];
    //#endregion
    //#region -- Markup -- 
    const back_redirect = () => {
        navigate("/DetailsPage");
    };
    const titleWithBackButton = <div style={{ display: "flex" }}>
        <div style={{ height: "35px", width: "35px", cursor: "pointer" }} onClick={back_redirect}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ padding: "5px", border: "1px solid lightgray" }}>
                <path d="M19 9H3.661l5.997-5.246a1 1 0 00-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 00-.177.24c-.018.03-.045.054-.059.087a.975.975 0 000 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 001.411-.095 1 1 0 00-.095-1.411L3.661 11H19a1 1 0 000-2z" fill="#5C5F62" />
            </svg>
        </div>
        <span style={{ margin: "0 10px", fontWeight: "bold" }}>
            One Time Plan
        </span></div>;
    //#endregion
    useEffect(() => {
        getPlanInfo()
    }, [])


    return (
        <Page title={titleWithBackButton}>
            <HorizontalGrid columns={isMobile ? 1 : ["twoThirds", "oneHalf"]} gap={isMobile ? "2" : "4"}>
                <AlphaCard>
                    <AlphaCard>
                        <Text variant="headingSm" as="h6">Select Plan Value</Text>
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            value={selected}
                            placeholder='Select Plan Value'
                        />
                    </AlphaCard><br />
                    <AlphaCard>
                        <Text variant="headingSm" as="h6">Plan Name</Text>
                        <TextField
                            value={plaNameValue}
                            onChange={handleSelectChangePlanName}
                            autoComplete="off"
                            placeholder='One Time Pay'
                        />
                    </AlphaCard><br />
                    <AlphaCard>
                        <Text variant="headingSm" as="h6">Plan Price</Text>
                        <TextField
                          type='number'
                            value={planPrice}
                            onChange={setPlanPrice}
                            prefix="$"
                            placeholder='78XX09.00'
                        />
                    </AlphaCard><br />
                    <AlphaCard>
                        {fields.map((field, index) => (
                            <div key={index}>
                                <TextField
                                    label={`Message ${index + 1}`}
                                    value={field}
                                    onChange={(value) => handleFieldChange(index, value)}
                                    autoComplete="off"
                                    connectedRight={<Button onClick={() => removeField(index)} destructive>Remove </Button>}
                                />
                            </div>
                        ))}
                        <br />
                        <Button onClick={addField} primary>
                            Add Field
                        </Button>
                    </AlphaCard><br />
                    <Button primary onClick={handleCreate}>Create</Button>
                </AlphaCard>
                <AlphaCard>
                    <Text as='h1' fontWeight='bold'>Plan Information</Text><br />
                    <Text as='h4' fontWeight='semibold'>Existing Plan</Text><br />
                    <Text as='h4' fontWeight='semibold'>Plan Name :- {planNameMessage}</Text><br />
                    <Text as='h4' fontWeight='semibold'>Plan Price :-  ${existingPrice}</Text><br />
                    <Text as='h4' fontWeight='semibold'>Plan Message</Text>
                    <ul className="plan__features">
                                {oneTimePayMessage.map((message, index) => (
                                    <li key={index}><b>{message}</b></li>
                                ))}
                            </ul>
                </AlphaCard>
            </HorizontalGrid>
            {toastMarkup}
        </Page>
    )
}

export default OneTimePlan
