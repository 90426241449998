import { AlphaCard, Button, Page, Text, TextField } from '@shopify/polaris'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../ContextApi/AuthContext';
import axios from 'axios'
import config from '../../../../config.json'
import UseToast from '../../../../Hooks/UseToast'

const DeleteMetafield = () => {
    const navigate = useNavigate()
    const { sellerDetails } = useContext(AuthContext)
    const { MyShopifyDomain } = sellerDetails;
    const { showToast, toastMarkup } = UseToast();


    const [productId, setProductId] = useState("")

    //#region -- Functions --
    const handleDelete = async () => {
        const url = `${config.APIURL}/api/customerTierRemove?Pid=${productId}`;
        try {
            const response = await axios.post(url,{},{
                headers: {
                    authorization: `Bearer ${MyShopifyDomain}`
                }
            });
            console.log('Customer Tier Remove Response:', response.data);
            if (response.data.status === 'error') {
                showToast(response.data.message, true)
                setTimeout(() => {
                    back_redirect()
                }, 2000);
            }
            else showToast('Customer tier removed successfully');

        } catch (error) {
            showToast('Failed to remove customer tier', true);
            console.error('Error removing customer tier:', error);
        }
    }

    //#endregion


    //#region -- Markup -- 
    const back_redirect = () => {
        navigate("/DetailsPage");
    };
    const titleWithBackButton = <div style={{ display: "flex" }}>
        <div style={{ height: "35px", width: "35px", cursor: "pointer" }} onClick={back_redirect}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ padding: "5px", border: "1px solid lightgray" }}>
                <path d="M19 9H3.661l5.997-5.246a1 1 0 00-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 00-.177.24c-.018.03-.045.054-.059.087a.975.975 0 000 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 001.411-.095 1 1 0 00-.095-1.411L3.661 11H19a1 1 0 000-2z" fill="#5C5F62" />
            </svg>
        </div>
        <span style={{ margin: "0 10px", fontWeight: "bold" }}>
            Delete Customer Metafield
        </span></div>;
    //#endregion
    return (
        <Page title={titleWithBackButton}
            compactTitle>
            <AlphaCard>

                <Text variant="headingSm" as="h6">Enter product id</Text>
                <TextField
                    value={productId}
                    onChange={setProductId}
                    autoComplete="off"
                    placeholder='Eg: 123XXXXXX56'
                /><br />
                <Button destructive onClick={handleDelete}>Delete Metafield</Button>

            </AlphaCard>

            {toastMarkup}
        </Page>
    )
}

export default DeleteMetafield