import { AlphaCard, Button, Checkbox, Page, Text, TextField } from '@shopify/polaris'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../ContextApi/AuthContext';
import axios from 'axios'
import config from '../../../../config.json'
import UseToast from '../../../../Hooks/UseToast'

const CreateProductDiscount = () => {
    const navigate = useNavigate()
    const { sellerDetails } = useContext(AuthContext)
    const { MyShopifyDomain } = sellerDetails;
    const { showToast, toastMarkup } = UseToast();

    const [titleText, setTitleText] = useState("")
    const [isProductDiscountCombined, setIsProductDiscountCombined] = useState(false)
    const [isOrderDiscountCombined, setIsOrderDiscountCombined] = useState(false)
    const [isShippingDiscountCombined, setIsShippingDiscountCombined] = useState(false)
    const [isCurrentDate, setIsCurrentDate] = useState(false)
    //#region -- Functions --
    const handleSubmit = async () => {
        const url = `${config.APIURL}/api/createPDFunctionRule`;
        const requestBody = {
            Shop: MyShopifyDomain,
            title: titleText,
            isProductDiscountCombined: true,
            isOrderDiscountCombined: true,
            isShippingDiscountCombined: true,
            isCurrentDate: isCurrentDate ? new Date() : null
        }
        console.log("requestBody: ", requestBody);
        try {
            const response = await axios.post(url, requestBody);
            if (response.data.status === "success") {
                showToast(response.data.message)
                setTimeout(() => {
                    back_redirect()
                }, 2000);
            }
            else showToast("Failed to create. Try Again", true)
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleUpdate = async () => {
        const url = `${config.APIURL}/api/updatePDFunctionRule`;
        try {
            const response = await axios.get(url,{
                headers: {
                    authorization: `Bearer ${MyShopifyDomain}`
                }
            });
            if (response.data.status === "success") {
                showToast(response.data.message)
                setTimeout(() => {
                    back_redirect()
                }, 2000);
            }
            else showToast("Update Failed", true)
        } catch (error) {
            console.error('Error updating rule:', error);
            showToast("Update Failed")
        }
    };
    const handleDelete = async () => {
        const url = `${config.APIURL}/api/DeltePDFunctionRule`;
        try {
            const response = await axios.get(url,{
                headers: {
                    authorization: `Bearer ${MyShopifyDomain}`
                }
            });
            if (response.data.status === "success") {
                showToast(response.data.message)
                setTimeout(() => {
                    back_redirect()
                }, 2000);
            }
            else showToast("Delete Failed", true)
        } catch (error) {
            showToast('Delete Failed')
            console.error('Error delete rule:', error);
        }
    }

    //#endregion
    //#region -- Markup -- 
    const back_redirect = () => {
        navigate("/DetailsPage");
    };
    const titleWithBackButton = <div style={{ display: "flex" }}>
        <div style={{ height: "35px", width: "35px", cursor: "pointer" }} onClick={back_redirect}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ padding: "5px", border: "1px solid lightgray" }}>
                <path d="M19 9H3.661l5.997-5.246a1 1 0 00-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 00-.177.24c-.018.03-.045.054-.059.087a.975.975 0 000 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 001.411-.095 1 1 0 00-.095-1.411L3.661 11H19a1 1 0 000-2z" fill="#5C5F62" />
            </svg>
        </div>
        <span style={{ margin: "0 10px", fontWeight: "bold" }}>
            Create Product Discount
        </span></div>;
    //#endregion
    return (
        <Page title={titleWithBackButton}
            compactTitle>
            <AlphaCard>
                <AlphaCard>
                    <Text variant="headingSm" as="h6">Product Discount Title</Text>
                    <TextField
                        value={titleText}
                        onChange={setTitleText}
                        autoComplete="off"
                        placeholder='Eg: BFCL sale'
                        helpText='Title will be displayed in the listing for your reference'
                    />
                </AlphaCard><br />
                <AlphaCard>
                    <Checkbox label="Product Discount Combined" checked={isProductDiscountCombined} onChange={setIsProductDiscountCombined} />
                </AlphaCard><br />
                <AlphaCard>
                    <Checkbox label="Order Discount Combined" checked={isOrderDiscountCombined} onChange={setIsOrderDiscountCombined} />
                </AlphaCard><br />
                <AlphaCard>
                    <Checkbox label="Shipping Discount Combined" checked={isShippingDiscountCombined} onChange={setIsShippingDiscountCombined} />
                </AlphaCard><br />
                <AlphaCard>
                    <Checkbox label="Current Date" checked={isCurrentDate} onChange={setIsCurrentDate} />
                </AlphaCard><br />
                <Button primary onClick={handleSubmit}>Submit</Button>
            </AlphaCard><br />
            <AlphaCard>
                <Text variant="headingSm" as="h6">UPDATE FUNCTION DISCOUNT</Text><br />
                <Button primary onClick={handleUpdate}>Update Discount</Button>
            </AlphaCard><br />
            <AlphaCard>
                <Text variant="headingSm" as="h6">DELETE FUNCTION DISCOUNT</Text><br />
                <Button destructive onClick={handleDelete}>Delete Discount</Button>
            </AlphaCard>
            {toastMarkup}
        </Page >

    )
}

export default CreateProductDiscount